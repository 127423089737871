/**

	TABLE OF CONTENTS

	// jQuery extend
		easing - easeInQuad
		wait
		onpopstate

	// Document and Window events
		Document Ready
		Window Resize
		Window Scroll

	// Ready actions
		ressac_document_ready
		ressac_ajax_ready
		ressac_load_completed

	// Utilities
		shuffle_array
		get_url_param
		update_url
		update_url_param
		init_scroll
		get_hash
		get_page
		get_param

	// AJAX
		init_ajax
		load_with_ajax7

	// retina
		init_retina

	// Sidebar
		init_sidebar
		reset_sidebar
		sidebar_animation
		open_sidebar
		close_sidebar

	// Sticky header
		show_hide_sticky
		show_sticky_header
		hide_sticky_header

	// Filter Case
		init_filter
		filter_on_load
		pseudo_select_open
		pseudo_select_close
		pseudo_option
		update_pseudo_option
		isotope_filter

	// Video
		init_video
		close_video_popup
		open_this_popup
		close_this_popup

	// Carousel
		destroy_carousel
		init_carousel
		build_carousel
		counter

	// Floating
		floating_init
		case_update_margin
		feed_floating_update_margin
		update_negative_margin

	// Table
		init_table

	// Feed images
		init_random_images
		random_images

	// Loading / Transition animation
		init_loading
		reset_loading
		loading_color
		loading_animation
		loading_logo
		loading_no_logo


	// Reveal
		reveal
		callback_animate_number

*/

/**
 * jQuery extend
 */

// t: current time, b: begInnIng value, c: change In value, d: duration
jQuery.easing['jswing'] = jQuery.easing['swing'];

jQuery.extend(jQuery.easing, {
    def: 'easeInQuad',
    easeInQuad: function(x, t, b, c, d) {
        return c * (t /= d) * t + b;
    }
});



// Fn to allow an event to fire after all images are loaded
jQuery.fn.imagesLoaded = function() {

    // get all the images (excluding those with no src attribute)
    var $imgs = this.find('img[src!=""]');
    // if there's no images, just return an already resolved promise
    if (!$imgs.length) { return $.Deferred().resolve().promise(); }

    // for each image, add a deferred object to the array which resolves when the image is loaded (or if loading fails)
    var dfds = [];
    $imgs.each(function() {

        var dfd = $.Deferred();
        dfds.push(dfd);
        var img = new Image();
        img.onload = function() { dfd.resolve(); }
        img.onerror = function() { dfd.resolve(); }
        img.src = this.src;

    });

    // return a master promise object which will resolve when all the deferred objects have resolved
    // IE - when all the images are loaded
    return $.when.apply($, dfds);

}


jQuery.wait = function(callback, seconds) {
    return window.setTimeout(callback, seconds * 1000);
}

/* To capture history navigation, and call AJAX instead of refresh the page */
window.onpopstate = function(event) {
    load_with_ajax(document.location.href, false);
};

/**
 * Document and Window events
 */

jQuery(document).ready(function() {
    console.log('Ressac - Ready !');
    document_ready();
    //  loadclient();
    // loadlist();


});

jQuery(window).on('load', function() {
    init_loading();
    loadlist();
});


/***imagen home */



jQuery(window).resize(function() {
    if (!jQuery(window).width() < 767) {
        reset_container_position();
    }
});

window.lastScrollTop = 0;
jQuery(window).scroll(function() {
    show_hide_sticky(lastScrollTop);
    close_video_popup();
});

/** 
 * Ready actions
 */

/** 
 * document_ready
 * A collection of function to be called on document first load,
 * and after the AJAX call is completed
 */

function document_ready() {

    init_ajax();
    // init_loading();

    init_sidebar();

    init_retina();
    init_floating();
    init_table();
    init_video();
    init_random_images();
    init_filter();

    video_lazy_load();
    inview_video();

    // New Timeline
    window.lineHeight();
}

/** 
 * ajax_ready
 * A collection of function to be called after the AJAX call is completed
 */

function ajax_ready() {

    close_sidebar();

    init_retina();
    init_floating();
    init_table();
    init_video();
    init_random_images();
    init_filter();

    video_lazy_load();
    inview_video();

    loadlist();

    

}

function load_completed(hash) {

    reset_loading();
    reveal();
    init_carousel();
    init_scroll(hash);
    
    // New Timeline
    window.lineHeight();
}



/**
 * Utilities
 */

/**
 * shuffle_array
 */

function loadclient() {

    ScrollReveal().reveal('.mas ', {
        delay: 800,
        useDelay: 'onload',
        reset: true,
    })

    ScrollReveal().reveal('.mas:first-child ', {
        delay: 0,
        interval: 80
    })
}

function shuffle_array(array) {

    var currentIndex = array.length,
        temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;

}

/**
 * 
 * loas list
 * 
 */

function loadlist() {

    var list = jQuery('.ul_lista');
    console.log(list);
    if (list.length > 0) {
        var listHeight = list.outerHeight();
        var winWidth = jQuery(window).width();
        var winHeight = jQuery(window).height();
        var listTop = list.offset().top;
        var listBottom = listHeight + listTop;
        var wrapperHeight = listHeight * 0.415;

        if (winWidth > 767) {
            jQuery('.listado').height(wrapperHeight);


            jQuery(window).on('scroll', function(){
                var trigger = window.pageYOffset + winHeight;
                /* console.log(window.pageYOffset);
                console.log(listTop); */
                if (listTop < trigger) {
                    var scrollPerc = trigger / listBottom;
                    var scalePerc = 1 - scrollPerc;
                    // console.log(scalePerc);
                    jQuery('.ul_lista').css('transform', 'scale(' + scalePerc + ',' + scalePerc + ')');
                }
            });
        } else {
            jQuery('.ul_lista').removeAttr('style');
            Query('.listado').removeAttr(wrapperHeight);
        }
    }
}



/**
 * 
 * scroll the list of client
 */

function listaScroll(target, duration) {
    var target = document.querySelector(target);
    var targetPosition = target.getBoundingClientRect().top;
    var startPosition = window.pageYOffset;
    var distance = targetPosition - startPosition;

    console.log(distance);
    /*
  window.addEventListener('scroll', ()=>{
	  const scrolla = document.documentElement.scrollHeight - window.innerHeight;
	  const scrollb = window.scrollY;
		
	  if(Math.ceil(scrollb) === scrolla){
         alert('estoy aqui');
	  }

  });
*/

}

/**
 * get_url_param
 * Retrieve the value of a giving parameter in the url
 */

function get_url_param(sParam) {

    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }

};



/**
 * update_url
 * Push url in history
 */

function update_url(new_path) {
    history.pushState({ path: new_path }, '', new_path);
}

/**
 * update_url_param
 * Update a the filter_string and filter_key, and push in history
 */

function update_url_param(string, key) {
    var new_path = window.location.protocol + "//" + window.location.host + window.location.pathname + '?filter_string=' + string + '&filter_key=' + key;
    update_url(new_path);
}

function init_scroll(hash) {

    // if we have an anchor
    if ('' != hash &&
        jQuery(hash).length > 0) {

        scroll_to = jQuery(hash).offset().top;

        jQuery('html, body').stop().animate({
            'scrollTop': scroll_to - 40
        }, 900, 'swing', function() {});

    }

}

function get_hash(url) {

    // remove param
    url_param_split = url.split('?');
    url_no_param = url_param_split[0];

    // remove hash
    url_hash_split = url_no_param.split('#');
    hash = (url_hash_split.length > 1) ? '#' + url_hash_split[1] : '';

    return hash;

}

function get_page(url) {

    // remove param
    url_param_split = url.split('?');
    url_no_param = url_param_split[0];

    // remove hash
    url_hash_split = url_no_param.split('#');

    page = url_hash_split[0];

    return page;

}

function get_params(url) {

    url_param_split = url.split('?');
    params = (url_param_split.length > 1) ? '?' + url_param_split[1] : '';

    return params;
}











/**
 * AJAX
 */


/**
 * init_ajax
 * Capture all click on 'a' and use AJAX to load content
 * Ignore external url
 */

function init_ajax() {

    jQuery('body').on('click', 'a', function(e) {

        // first remove trailing slash
        url = jQuery(this).attr('href').replace(/\/$/, "").toLowerCase();
        base_url = BASE_URL.replace(/\/$/, "").toLowerCase();

        if (url.indexOf(base_url) >= 0) {
            e.preventDefault();
            load_with_ajax(url, true);
        }

    });

}

/**
 * load_with_ajax 
 */

function load_with_ajax(url, push_state) {

    container = jQuery('#ajax_container');

    var data = {
        'page': get_page(url),
        'hash': get_hash(url),
        'params': get_params(url),
    }

    var ajax_url = data.page + "/view_ajax/";

    jQuery.ajax({
        type: "POST",
        url: ajax_url,
        data: data,
        cache: false,
        success: function(response) {

            try {
                response_json = JSON.parse(response);
            } catch (e) {
                window.location.href = url;
                return;
            }


            jQuery('html').addClass('ajax_call');

            // start the transition
            loading_animation(data.hash);

            setTimeout(function() {

                jQuery(container).html(response_json.html).promise().done(function() {

                    // update body with new data
                    jQuery('body').attr('data-view', response_json.view);
                    jQuery('body').attr('data-lang', response_json.lang);
                    jQuery('body').attr('data-page', response_json.page);

                    reset_container_position();

                    // update path
                    // prevent update_url when we use history to navigate
                    if (push_state) {

                        update_url(data.page + data.hash + data.params);

                        var url = new URL(data.page);
                        var virtualPath = url.pathname;
                        
                        // console.log( virtualPath );

                        dataLayer.push({
                            'event': 'Pageview',
                            'url': url,
                            'path': virtualPath
                        });
                    }

                    ajax_ready();

                });

            }, 750); // let's the first panel fully cover before updating content

        },
        error: function(request, status, error) {

            // load 404 page
            window.location.href = url;

        }

    });

}













/**
 * init_retina
 * this function add .retina to body, allowing retina image in style.css, 
 * and replace inline css background-image when retina is available
 */

function init_retina() {

    var query = "(-webkit-min-device-pixel-ratio: 1.5),\
	              (min--moz-device-pixel-ratio: 1.5),\
	              (-o-min-device-pixel-ratio: 3/2),\
	              (min-device-pixel-ratio: 1.5),\
	              (min-resolution: 144dpi),\
	              (min-resolution: 1.5dppx)";

    if (window.devicePixelRatio > 1 || (window.matchMedia && window.matchMedia(query).matches)) {
        jQuery('body').addClass('retina');
        var elems = document.getElementsByTagName('*');
        for (var i = 0; i < elems.length; i++) {
            var attr = elems[i].getAttribute('data-2x');
            if (attr) {
                elems[i].style.cssText += 'background-image: url(' + attr + ')';
            }
        }
    }


}




/**
 * init_sidebar
 * Only required on first load, sidebar is not part of the AJAX load
 */

function init_sidebar() {

    jQuery('body').on('click', '.header_sidebar_open', function() {
        open_sidebar();
    });

    jQuery('body').on('click', '#header_sidebar_close', function() {
        close_sidebar();
    });

}

/**
 * reset_sidebar
 * make sure to remove leftover of #ajax_container animation
 */

function reset_container_position() {
    jQuery('#ajax_container').css('left', '');
}

/**
 * Open Sidebar
 */

function open_sidebar() {

    that = jQuery("#header_sidebar");

    if (!jQuery(that).hasClass('animating')) {

        jQuery(that).addClass('animating');

        // Mesure the height for a more precise animation
        header_sidebar_height = jQuery(that).outerHeight();
        header_sidebar_width = jQuery(that).outerWidth();

        if (jQuery(window).width() < 767) {

            // Position the element just out of view
            jQuery(that).css('top', -80);
            jQuery(that).css('left', header_sidebar_width * -1);

            jQuery(that).animate({
                left: "+=" + (header_sidebar_width),
            }, 800, 'easeOutSine', function() {
                jQuery(that).removeClass('animating');
            });

            jQuery('#ajax_container').animate({
                left: "+=" + (header_sidebar_width / 2),
            }, 800, 'easeOutSine', function() {

            });

        } else {

            reset_container_position();

            remove = 80; // remove px from the animation

            // Position the element just out of view
            jQuery(that).css('top', header_sidebar_height * -1);
            jQuery(that).css('left', 0);

            jQuery(that).animate({
                top: "+=" + (header_sidebar_height - remove),
            }, 800, 'easeOutBack', function() {
                jQuery(that).removeClass('animating');
            });

        }

    }

}

/**
 * Close Sidebar
 */

function close_sidebar() {

    that = jQuery("#header_sidebar");

    // check if visible
    sidebar_offset = jQuery('#header_sidebar_close').offset();
    window_scrolltop = jQuery(window).scrollTop();

    sidebar_is_visible = window_scrolltop < sidebar_offset.top && 0 < sidebar_offset.left ? true : false;

    if (sidebar_is_visible &&
        !jQuery(that).hasClass('animating')) {

        jQuery(that).addClass('animating');

        // Mesure the height for a more precise animation
        header_sidebar_height = jQuery(that).outerHeight();
        header_sidebar_width = jQuery(that).outerWidth();

        if (jQuery(window).width() < 767) {

            // Position the element before animation
            jQuery(that).css('top', '');
            jQuery(that).css('left', 0);

            jQuery(that).animate({
                left: "-=" + (header_sidebar_width),
            }, 800, 'easeOutSine', function() {
                jQuery(that).removeClass('animating');
                jQuery(that).css('left', '');
            });

            jQuery('#ajax_container').animate({
                left: "0",
            }, 800, 'easeOutSine', function() {});

        } else {

            reset_container_position();

            // Position the element before animation
            jQuery(that).css('top', -80);
            jQuery(that).css('left', '');

            jQuery(that).animate({
                top: "-=" + (header_sidebar_height),
            }, 800, 'easeOutBack', function() {
                jQuery(that).removeClass('animating');
                jQuery(that).css('top', '');
            });

        }

    }

}





/**
 * Sticky Header
 */


/**
 * show_hide_sticky
 */

function show_hide_sticky(lastScrollTop) {

    var st = jQuery(window).scrollTop();

    if (st < lastScrollTop && st >= 100) {
        show_sticky_header();
    } else {
        hide_sticky_header();
    }

    window.lastScrollTop = st;

}

/**
 * show_sticky_header
 */

function show_sticky_header() {

    if (!jQuery('#sticky_header').hasClass('animating') &&
        !jQuery('#sticky_header').hasClass('visible')) {

        jQuery('#sticky_header').addClass('animating'); // to prevent multiple animation in queue

        jQuery("#sticky_header").animate({
            top: 0,
        }, 250, "linear", function() {
            jQuery("#sticky_header").removeClass('hidden');
            jQuery("#sticky_header").addClass('visible');
            jQuery("#sticky_header").removeClass('animating');

            // check if we are on top - if true, hide now
            if (jQuery(window).scrollTop() < 100) {
                hide_sticky_header();
            }

        });

    }

}

/**
 * hide_sticky_header
 */

function hide_sticky_header() {

    if (!jQuery('#sticky_header').hasClass('animating') &&
        !jQuery('#sticky_header').hasClass('hidden')) {

        jQuery('#sticky_header').addClass('animating'); // to prevent multiple animation in queue
        sticky_height = jQuery('#sticky_header').outerHeight();

        jQuery("#sticky_header").animate({
            top: (-1 * sticky_height - 50),
            opacity: 0,
        }, 250, "linear", function() {
            jQuery("#sticky_header").removeClass('visible');
            jQuery("#sticky_header").addClass('hidden');
            jQuery("#sticky_header").removeClass('animating');
            jQuery("#sticky_header").css('opacity', '');
        });

    }

}






/**
 * Filter Case Studies
 */

/**
 * init_filter
 */

function init_filter() {

    if (!jQuery('.widget_feed_case #filter_case').length) {
        return;
    }

    jQuery('body').on('click', '#filter_case.filter_hidden', function() {
        pseudo_select_open(jQuery(this));
    });

    jQuery('body').on('click', '#filter_case.filter_visible', function() {
        pseudo_select_close();
    });

    jQuery('body').on('click', '#filter_case .pseudo_option', function() {
        pseudo_option(jQuery(this), $isotope);
    });

    /**
     * Isotope library
     * https://isotope.metafizzy.co/
     */

    // init Isotope
    var $isotope = jQuery('.widget_feed_case_items').isotope({
        itemSelector: '.widget_feed_case_items .item',
        layoutMode: 'masonry',
        originLeft: false
    });

    // isotope doesn't calculate position and size properly on first load
    setTimeout(function() {
        $isotope.isotope('layout');
        filter_on_load($isotope);
    }, 125);

}

/*
jQuery( window ).on('scroll', function() {

	// Hide #filter_case.filter_visible if out of screen
	if( jQuery( '#filter_case.filter_visible .filter_case_absolute' ).length > 0 ) {
		
		if( jQuery('#filter_case.filter_visible .filter_case_absolute').is(':offscreen') ) {
			pseudo_select_close();
		}

	}

});
*/

/**
 * filter_on_load
 */








function filter_on_load($isotope) {

    // Check if we have param and pre-filter if true
    filter_key = get_url_param('filter_key');
    filter_string = get_url_param('filter_string');

    if (filter_key && filter_string) {
        update_pseudo_option(filter_string, filter_key);
        isotope_filter(filter_key, $isotope);
    } else {
        isotope_filter('all', $isotope);
    }

}



/**
 * pseudo_select_open
 */

function pseudo_select_open(that) {
    jQuery('body').addClass('filter_visible');
    jQuery(that).addClass('filter_visible');
    jQuery(that).removeClass('filter_hidden');
}

/**
 * pseudo_select_close
 */

function pseudo_select_close() {
    jQuery('#filter_case.filter_visible').addClass('filter_hidden');
    jQuery('.filter_visible').removeClass('filter_visible');
}

/**
 * pseudo_option
 */

function pseudo_option(that, $isotope) {

    that_filter_string = jQuery(that).attr('data-filter-string');
    that_filter_key = jQuery(that).attr('data-filter-key');

    update_pseudo_option(that_filter_string, that_filter_key);
    update_url_param(that_filter_string, that_filter_key);
    isotope_filter(that_filter_key, $isotope);


}

/**
 * update_pseudo_option
 */

function update_pseudo_option(string, key) {

    string = string.substring(1, 0).toUpperCase() + string.substring(1).toLowerCase();
    key = key.toLowerCase();

    // Update the active filter
    jQuery('#filter_case .filter_active').text(string);

    // Remove previous .current_filter
    jQuery('.current_filter').removeClass('current_filter');

    // Add .current_filter
    jQuery('[data-filter-key=' + key + ']').addClass('current_filter');

}

/**
 * isotope_filter
 */

function isotope_filter(key, $isotope) {

    key = key.toLowerCase();

    if ('all' != key) {
        $isotope.isotope({ filter: ".filter-industry-" + key });
    } else {
        $isotope.isotope({ filter: "*" });
    }

}



















/**
 * Video
 */


function init_video() {

    jQuery('body').on('click', '.widget_section_video_placeholder', function() {

       var videoUrl = jQuery(this).data('video');
       var videoPoster = jQuery(this).data('poster');

        open_this_popup(videoUrl,videoPoster);

    });

    jQuery('body').on('click', '.widget_section_video_backdrop', function() {

        close_video_popup();

    });

}

/* function close_video_popup() {

    if (jQuery('.video_popup_visible').length > 0) {

        // Close popup when scrolling
        jQuery('.video_popup_visible').each(function() {

            id = jQuery(this).attr('data-popup-id');
            popup = jQuery('[data-popup-id=' + id + ']');

            close_this_popup(popup);

        });

    }

} */

function open_this_popup(video,poster) {
    var popup = jQuery('.widget_section_video_popup');

    var popupVideo = '<video controls poster="' + poster + '"><source src="' + video + '" type="video/mp4"></video>';


    if (!jQuery(window).width() < 980 && !popup.hasClass('video_popup_animating')) {
        jQuery('.widget_section_video').append(popupVideo);
        popup.addClass('video_popup_animating');
        popup.addClass('video_popup_visible');

        popup.animate({
            opacity: 1
        }, 500, function() {
            popup.removeClass('video_popup_animating');
        });

    }

}

function close_video_popup() {

    var popup = jQuery('.widget_section_video_popup');

    if (!jQuery(window).width() < 980 &&
        !popup.hasClass('video_popup_animating')) {

        popup.addClass('video_popup_animating');

        popup.animate({
            opacity: 0
        }, 500, function() {

            // stop player
            jQuery('.widget_section_video').empty();

            popup.removeClass('video_popup_visible');
            popup.removeClass('video_popup_animating');

        });

    }

}


















/**
 * Carousel
 */

function destroy_carousel(callback) {

    if (jQuery('.owl-carousel').length > 0) {
        jQuery('.owl-carousel').owlCarousel('destroy');
    }

    if (typeof $owl !== 'undefined') {
        $owl = undefined;
    }

    callback();

}
window.init_carousel = function() {
    if (jQuery('.feed-carousel').length > 0) {
        destroy_carousel(build_carousel);
    } else if (jQuery('.trainings-carousel').length > 0 ) {
        if( jQuery(window).width() > 600 ) {
            destroy_carousel(build_carousel);
        } else {
            carouselMobile();
        }
    }
}

function build_carousel() {

    if (jQuery('.owl-carousel').length > 0) {

        var desktopMargin = 60,
            mobileMargin = 14;

        if ($('.owl-carousel').hasClass('trainings-carousel')) {
            desktopMargin = 0;
            mobileMargin = 0;
        }

        jQuery('.owl-carousel').imagesLoaded().then(function() {

            $owl = jQuery('.owl-carousel');

            if($owl.hasClass('trainings-carousel')) {
                $(window).on('resize', window.carouselOffset);
            }

            $owl.owlCarousel({
                responsive: {
                    // breakpoint from 0 up
                    0: {
                        margin: mobileMargin,
                    },
                    // breakpoint from 768 up
                    768: {
                        margin: desktopMargin,
                    }
                },
                items: 1,
                autoWidth: true,
                onInitialized: counter,
                onTranslated: counter
            });

            // Control the carousel with custom navigation
            jQuery('#feed_carousel_arrow_next').on('click', function() {
                $owl.trigger('next.owl.carousel');
            });
            jQuery('#feed_carousel_arrow_prev').on('click', function() {
                $owl.trigger('prev.owl.carousel');
            });

            // Events API Callback

            // Init Slide change
            // $owl.on('change.owl.carousel', function(event){
                
            // });

            // Slide has changed
            $owl.on('changed.owl.carousel', function(event){
                var itemIndex = event.item.index;
                var itemText = $('.owl-item:eq('+ itemIndex +') > .owl-carousel__item').data('text');

                $('.owl-item').removeClass('offsetLeft');
                if(itemIndex > 0) {
                    $('.owl-item:eq(' + (itemIndex - 1) +')').addClass('offsetLeft');
                }

                if (itemText) {
                    $('.carousel-info').fadeOut('fast', function(){
                        $('.carousel-info').text(itemText);
                        $('.carousel-info').fadeIn('fast');
                    });
                }
            });

        });

    }

}


/**
 * counter - callback function
 */

function counter(event) {

    var element = event.target; // DOM element, in this example .owl-carousel
    var items = event.item.count; // Number of items
    var item = event.item.index + 1 // Position of the current item

    // it loop is true then reset counter from 1
    if (item > items) {
        item = item - items;
    }

    lead0items = 10 > items ? "0" : "";
    lead0item = 10 > item ? "0" : "";

    jQuery('#feed_carousel_counter').html(lead0item + item + "<span>/" + lead0items + items + "</span>");
    jQuery('#feed_carousel_counter_mobile').html(lead0item + item + "<span>/" + lead0items + items + "</span>");

    // Update position of #feed_carousel_progression_bar 
    // the item is 33% of it's container, we need to break the remaining 66% by the number of slide
    one_slide_percentage = 66.66 / (items - 1);
    new_margin_left = (item - 1) * one_slide_percentage;

    jQuery('#feed_carousel_progression_pointer').css('marginLeft', new_margin_left + '%');

    // add class to custom nav 
    if (new_margin_left == 0) {
        jQuery('#feed_carousel_arrow_prev').addClass('disabled');
    } else {
        jQuery('#feed_carousel_arrow_prev').removeClass('disabled');
    }

    if (items == item) {
        jQuery('#feed_carousel_arrow_next').addClass('disabled');
    } else {
        jQuery('#feed_carousel_arrow_next').removeClass('disabled');
    }

}


















/**
 * Floating
 */


function init_floating() {

    if (jQuery('.widget_feed_floating.feed_floating_ver').length > 0) {

        feed_floating_update_margin();

        jQuery(window).resize(function() {
            feed_floating_update_margin();
        });

    }

    case_update_margin();

    jQuery(window).resize(function() {
        case_update_margin();
    });


}

function case_update_margin() {

    if (jQuery('#row_case_challenge .widget_section_case .image-left').length > 0) {

        jQuery('#row_case_challenge .widget_section_case .image-left').each(function() {
            update_negative_margin(this, 'left', 1, 1086, 1366);
        });

    }

}

function feed_floating_update_margin() {

    jQuery('.widget_feed_floating.feed_floating_ver .item.even.a_third').each(function() {
        update_negative_margin(this, 'left', 1, 1086, 1366);
    });

    jQuery('.widget_feed_floating.feed_floating_ver .item.odd.a_third').each(function() {
        update_negative_margin(this, 'right', 1, 1086, 1366);
    });

    jQuery('.widget_feed_floating.feed_floating_ver .item.even.not_a_third').each(function() {
        update_negative_margin(this, 'left', 0.5, 1086, 1366);
    });

    jQuery('.widget_feed_floating.feed_floating_ver .item.odd.not_a_third').each(function() {
        update_negative_margin(this, 'right', 0.5, 1086, 1366);
    });

    jQuery('.widget_feed_floating.feed_floating_ver .item.odd').each(function() {
        update_negative_margin(this, 'top', 1, 1086, 1366);
    });

}


function update_negative_margin(element, side, factor, start_width, max_width) {

    max_width = null != max_width ? max_width : 9999;
    start_width = null != start_width ? start_width : 1086;
    // if window width is smaller than large_boxed
    window_width = jQuery(window).outerWidth();

    // original logic used 1086px breakpoint instead of 906px
    if (window_width < max_width && window_width > start_width) {

        ver_algorytm = -1 * ((window_width - start_width) / start_width) * 200;
        hor_alogrytm = -1 * (window_width - start_width) / 2;

        negative_margin = 'top' == side || 'bottom' == side ? ver_algorytm : hor_alogrytm;

        jQuery(element).css('margin-' + side, negative_margin * factor);

    } else {

        jQuery(element).css('margin-left', '');
        jQuery(element).css('margin-right', '');
        jQuery(element).css('margin-top', '');
        jQuery(element).css('margin-bottom', '');

    }

}



















/**
 * Table
 */

function init_table() {

    if (!jQuery('.widget_table .load_more').length > 0) {
        return;
    }

    jQuery('body').on('click', '.widget_table .load_more', function() {

        that_table = jQuery(this).closest('.widget_table');
        that_preload = jQuery(that_table).attr('data-preload');

        // show the next X .widget_table_row 
        if (jQuery('.widget_table .not_loaded').length > 0) {

            counter = 0;

            jQuery('.widget_table .not_loaded').each(function() {

                if (counter < that_preload) {

                    jQuery(this).slideDown(500, function() {

                        jQuery(this).removeClass('not_loaded');

                        if (jQuery('.widget_table .not_loaded').length == 0) {
                            jQuery('.widget_table .load_more').css('display', 'none');
                        }

                    });

                }

                counter++;

            });

        } else {

            jQuery('.widget_table .load_more').css('display', 'none');

        }

    });

}



















/**
 * Feed images
 */

function init_random_images() {

    if (jQuery('.widget_feed_images').length > 0) {

        jQuery('.widget_feed_images').each(function(index, widget_feed_images) {

            var speed = jQuery(this).attr('data-speed');

            random_images(speed, widget_feed_images);

        });

    }

}


function random_images(speed, that) {

    var hidden_class = jQuery(window).width() < 767 ? '.hidden_mobile' : '.hidden_desktop';

    var item_visible = jQuery(that).children('.widget_feed_images .item').not(hidden_class);
    var item_hidden = jQuery(that).children('.widget_feed_images .item' + hidden_class);

    if (jQuery(item_hidden).length > 0) {

        var visible = jQuery(item_visible);
        var visible_length = jQuery(visible).length;

        // Get hidden
        var hidden = jQuery(item_hidden);
        var hidden_length = jQuery(hidden).length;

        // random .widget_feed_image_item
        var random_visible_pos = Math.floor(Math.random() * visible_length);
        var random_visible = jQuery(visible).get(random_visible_pos);
        var random_visible_path = jQuery(random_visible).attr('data-path');

        // get path
        // random .widget_feed_image_hidden
        var random_hidden_pos = Math.floor(Math.random() * hidden_length);
        var random_hidden = jQuery(hidden).get(random_hidden_pos);
        var random_hidden_path = jQuery(random_hidden).attr('data-path');

        // switch both
        jQuery(random_visible).fadeOut(500, function() {

            if (random_hidden_path != undefined && random_visible_path != undefined) {
                // Update random_visible path and CSS
                jQuery(random_visible).attr('data-path', random_hidden_path);
                jQuery(random_visible).css('background-image', 'url(' + random_hidden_path + ')');

                // Update random_hidden path
                jQuery(random_hidden).attr('data-path', random_visible_path);
                jQuery(random_hidden).css('background-image', 'url(' + random_visible_path + ')');
            }

            // Show the updated visible
            jQuery(random_visible).fadeIn(500, function() {
                setTimeout(random_images, speed, speed, that); // recall the function once animation completed
            });

        });

    }

}

/**
 * Video Lazy Load 
 */
function video_lazy_load() {
    jQuery('video.lazy').each(function(){
        var thisVideo = jQuery(this).data('src');
        jQuery(this).append('<source src="' + thisVideo + '" type="video/mp4">');
    });
}

// Pause Video if outside the viewport
function inview_video() {
    var videoObserver = new IntersectionObserver( function(entries) {
        entries.forEach( function(entry) {
          if (entry.intersectionRatio > 0) {
            playVideo(entry.target);
          } else {
            pauseVideo(entry.target);
          }
        });
      }, {
        rootMargin: '0px'
      });

      /* jQuery('.inview-video').each(function(){
        var video = jQuery(this);
        videoObserver.observe(video);
      }); */

      var myVideos = document.querySelectorAll('.inview-video');
      myVideos = Array.from(myVideos);
      myVideos.forEach( function(video) {
        videoObserver.observe(video);
      });

      function playVideo(video) {
        video.play();
      }
      
      function pauseVideo(video) {
        video.pause();
      }
}



/**
 * Loading / Transition animation 
 */

/** 
 * init_loading
 */

function init_loading() {

    hash_requested = get_hash(window.location.href);

    if (jQuery('body[data-view=404]').length > 0 ||
        jQuery('body[data-view=404-marcel]').length > 0 ||
        jQuery('body[data-view=404-cleo]').length > 0) {
        reset_loading();
        return; // stop everything here
    } else {
        loading_animation(hash_requested);
    }

}

/** 
 * reset_loading
 */

function reset_loading() {

    jQuery('html').removeClass('loading_animation');

    jQuery("#loading").css('display', 'none');
    jQuery('#loading').css('left', '');
    jQuery('#loading').css('height', '');
    jQuery('#loading').css('opacity', '');

    jQuery('#loading .color_slide').css('left', '');
    jQuery('#loading .color_slide').css('display', '');

    jQuery('#loading_logo').css('opacity', '');
    jQuery('#loading_logo_container').css('width', '');

    reset_container_position();

}


/** 
 * loading_color
 */

function loading_color() {

    var color_pool = shuffle_array(Array('#82A7FA',
        '#3E72FF',
        '#9BDDF2',
        '#82A7FA',
        '#77E6C6',
        '#33D3A2',
        '#FFF488',
        '#FFDF98',
        '#FFBD6F',
        '#FDBAB1',
        '#FD8D8D',
        '#FF6589',
        '#F49ED6',
        '#D298FF',
        '#AE89FF'));

    count = 0;

    jQuery('#loading .color_slide').each(function() {

        // pick random color
        rand_color = color_pool[count];

        jQuery(this).css('background-color', rand_color);

        count++;

    });

    // update #loading_logo svg color with last color
    logo_color = color_pool[count - 1];

    jQuery('#loading_logo svg path').css('fill', logo_color);

}



function loading_animation(hash) {

    jQuery("#loading").css('display', '');

    anim_logo = jQuery('.ajax_call').length > 0 ? false : true;
    anim_speed = anim_logo ? 750 : 750;

    // defined color
    loading_color();

    // Delay between the 3 panels animations
    var delay_pool = anim_logo ? Array(0, 800, 1100) : Array(0, 300);
    var delay_last = delay_pool[delay_pool.length - 1];
    var delay_anim_logo = delay_last + anim_speed; // equal last delay value + animation length

    count = 0;

    // .loading_animation class prevent page scroll 
    // Show only after first panel fully cover - for ajax call
    if (!jQuery('.ajax_call').length > 0) {
        jQuery('html').addClass('loading_animation');
    } else {

        setTimeout(function() {
            jQuery('html').addClass('loading_animation');
        }, anim_speed);
    }

    // animation of the colored slide
    jQuery('#loading .color_slide').each(function() {

        if (undefined != delay_pool[count]) {

            jQuery(this).delay(delay_pool[count]).animate({
                left: "0",
            }, anim_speed, "easeInQuad", function() {
                //jQuery('html').addClass('loading_animation');
            });

        }

        count++;

    });

    // zoom out logo
    setTimeout(function() {

        if (anim_logo) {
            loading_no_logo(hash);
            // loading_logo(hash); - for now
        } else {
            loading_no_logo(hash);
        }

    }, delay_anim_logo);


}


/**
 * loading_no_logo 
 */

function loading_no_logo(hash) {

    jQuery('#loading').animate({
        left: '100%',
    }, 500, "easeInQuad", function() {
        load_completed(hash);
    });

}

/**
 * loading_logo 
 */

function loading_logo(hash) {

    // fadein logo
    jQuery('#loading_logo').animate({
        opacity: 1,
    }, 250, "easeInQuad", function() {});

    // zoom out logo
    jQuery('#loading_logo_container').animate({
        width: "50%",
    }, 250, "easeInQuad", function() {

        // hide #loading .color_slide
        jQuery('#loading .color_slide').css('display', 'none');

        setTimeout(
            function() {

                // Animation in parrallel - second zoom out
                jQuery('#loading_logo_container').animate({
                    width: 88,
                }, 1000, "easeInQuad", function() {});

                // Animation in parrallel - zoom out white panel
                jQuery('#loading').animate({
                    height: 99,
                }, 1000, "easeInQuad", function() {

                    // fadeout white panel
                    jQuery('#loading').animate({
                        opacity: 0,
                    }, 500, "easeInQuad", function() {
                        load_completed(hash);
                    });

                });

            }, 250); // delay before zoom out the logo

    });

}












/**
 * Reveal
 */

function reveal() {

    reveal_this_config = {
        delay: 250,
        distance: '50px',
        duration: 600,
        easing: 'cubic-bezier(0.5, 0, 0, 1)',
        interval: 600,
        opacity: 0,
        origin: 'bottom',
        rotate: {
            x: 0,
            y: 0,
            z: 0,
        },
        scale: 0.85,
        cleanup: false,
        container: document.documentElement,
        desktop: true,
        mobile: true,
        reset: false,
        useDelay: 'always',
        viewFactor: 0.0,
        viewOffset: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
        },
        afterReset: function(el) {},
        beforeReset: function(el) {},
        beforeReveal: function(el) {},
        afterReveal: function(el) {}
    }

    ScrollReveal(reveal_this_config).reveal('.reveal_this');
    ScrollReveal(reveal_this_config).reveal('#row_home_textocan');
    ScrollReveal(reveal_this_config).reveal('#row_home_feed_case .widget_feed_case_items .item');
    ScrollReveal(reveal_this_config).reveal('#row_case_feed_case .widget_feed_case_items .item');

    reveal_results_config = reveal_this_config;
    reveal_results_config.afterReveal = callback_animate_number;

    ScrollReveal(reveal_results_config).reveal('.reveal_results');

}

/**
 * Animate number
 * this function is called by Reveal script, once the element is revealed
 */

function callback_animate_number(el) {

    el.that_count = jQuery(el).find('span.count');

    el.count_to = jQuery(el.that_count).attr('data-count');
    el.count_duration = el.count_to > 40 ? 4000 : el.count_to * 100;

    jQuery(el.that_count).prop('Counter', 0).animate({
        Counter: el.count_to
    }, {
        duration: el.count_duration,
        easing: 'swing',
        step: function(now) {
            jQuery(el.that_count).text(Math.ceil(now));
        },
        complete: function() {
            jQuery(el.that_count).removeClass('count');
        }
    });

}

